import { Box, Dialog, Divider, InputBase, Typography } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { TFunction } from "next-i18next";
import React, { useEffect, useState } from "react";
import data_country from "../../public/data_country";
import useStyles from "../../styles/components/PhoneCodePicker";

type PhoneCodePickerProps = {
  readonly t: TFunction;
  handleSelectPhoneCode: (code: any) => void;
  onClose: () => void;
  open: boolean;
};

const PhoneCodePicker = (props: PhoneCodePickerProps) => {
  const classes = useStyles();
  const { open, handleSelectPhoneCode, t, onClose } = props;
  const [search, setSearch] = useState("");
  const [filteredSearch, setFilteredSearch] = useState(data_country);

  useEffect(() => {
    setFilteredSearch(
      data_country.filter(
        (val) =>
          val.name
            .toLocaleLowerCase()
            .match(new RegExp(search.toLocaleLowerCase())) ||
          val.dial_code.match(search)
      )
    );
  }, [search]);

  return (
    <Dialog open={open} onClose={onClose}>
      <Box className={classes.searchWrapper}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="24px"
        >
          <Typography variant="h2">{t("select-country-code")}</Typography>
          <Close onClick={onClose} className={classes.icon} />
        </Box>
        <Box className={classes.search}>
          <InputBase
            placeholder={t("look-for-the-name-or-country-code")}
            className={classes.searchPhoneCode}
            fullWidth
            value={search}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => {
              let pattern = /^[a-zA-Z0-9]+$/;
              if (pattern.test(e.target.value)) {
                setSearch(e.target.value);
              } else {
                setSearch(e.target.value.replace(/[^a-zA-Z0-9]/g, ""));
              }
            }}
            startAdornment={<Search color="primary" />}
            endAdornment={
              search.length !== 0 && (
                <Close onClick={() => setSearch("")} className={classes.icon} />
              )
            }
          />
        </Box>
      </Box>
      <Box className={classes.listCountry}>
        {filteredSearch.length ? (
          filteredSearch.map((res, index) => (
            <Box key={index}>
              <Box
                className={classes.countryWrapper}
                onClick={() => handleSelectPhoneCode(res)}
              >
                <Box display="flex" alignItems="center">
                  <img
                    src={`/img/flags/${res.country_code.toLocaleLowerCase()}.png`}
                    height={20}
                    width={30}
                    alt="country-flag"
                  />
                  <Typography className={classes.countryName}>
                    {res.name}
                  </Typography>
                </Box>
                <Typography>{res.dial_code}</Typography>
              </Box>
              {index + 1 !== filteredSearch.length && <Divider />}
            </Box>
          ))
        ) : (
          <Typography className={classes.emptyMessage}>
            {t("country-code-not-found")}
          </Typography>
        )}
      </Box>
    </Dialog>
  );
};

export default PhoneCodePicker;
