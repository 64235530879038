import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      borderRadius: 16,
      width: "100%",
      display: "flex",
      border: "1px solid #989898",
      padding: "12px 16px",
    },
    searchWrapper: {
      paddingBottom: 8,
      width: "100%",
      borderRadius: "4px 4px 0px 0px",
    },
    searchPhoneCode: {
      "& .MuiInputBase-input": {
        padding: 0,
        fontSize: 14,
        marginLeft: 8,
        width: "100% !important",
      },
    },
    icon: {
      cursor: "pointer",
    },
    listCountry: {
      backgroundColor: "#ffffff",
      overflow: "auto",
    },
    countryWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: 16,
      cursor: "pointer",
    },
    countryName: {
      fontWeight: 600,
      marginLeft: 20,
    },
    emptyMessage: {
      color: "#929292",
    },
  })
);

export default useStyles;
